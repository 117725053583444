import React, { useState, useEffect } from 'react';

const EventEmailData = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const handleWebhook = async event => {
      setEvents(prevEvents => [...prevEvents, event.data]);
    };

    window.addEventListener('sendgridEvent', handleWebhook);
    window.addEventListener('sendgridWebhook', handleWebhook);

    return () => {
      window.removeEventListener('sendgridEvent', handleWebhook);
      window.removeEventListener('sendgridWebhook', handleWebhook);
    };
  }, []);

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-3xl font-bold mt-10">Webhook Testing</h1>
      <div className="flex flex-wrap justify-center">
        {events.map((event, index) => (
          <div key={index} className="w-full md:w-1/3 p-10">
            <div className="bg-white rounded shadow p-6">
              <h2 className="text-lg font-bold mb-4">Event Email Data</h2>
              <p className="text-gray-600">Event Type: {event.event}</p>
              <p className="text-gray-600">Email: {event.email}</p>
              <p className="text-gray-600">Timestamp: {event.timestamp}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventEmailData;
